/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import base from '../config/env' // 引入域名
import router from '../router'
// import store from '../store/index';
import { Message } from 'element-ui'
import qs from 'qs'

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
// const toLogin = () => {
//   router.replace({
//     path: '/login',
//     query: {
//       redirect: router.currentRoute.fullPath
//     }
//   });
// }

// 创建axios实例
var instance = axios.create()
// 设置请求超时
instance.defaults.timeout = 8000
// 设置请求头
instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded' // 键值对
// instance.defaults.headers['Content-Type'] = 'application/json';   // json 字符串
// instance.defaults.headers['Content-Type'] = 'multipart/form-data';   // form

// https://www.yocamily.com/ds/
// 设置域名
instance.defaults.baseURL = base.baseURL //base.baseURL

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token  addOrder
 */
instance.interceptors.request.use(
  (config) => {
    //  console.log(config)
    if (config.url.search('upload') > -1) {
      console.log('upload')
    } else {
      config.data = qs.stringify(config.data)
    }
    // var obj = qs.parse(config.data)
    // if ('userId' in obj && !obj.userId) {
    //   console.log('no user id:', obj)
    //   //判断有userid但是没值的情况下
    //   // &&config.url!='/api/listGoodsClassify'
    //   router.push('/login')
    //   return
    // } else {
    //   return config
    // }
    return config
  },
  (error) => {
    Message.error('请求超时')
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  function (response) {
    const lang = localStorage.getItem('lang')
    let code = response.data.code
    switch (code) {
      case 0:
        return Promise.resolve(response.data)
      case 404:
        Message.error('请求地址不存在！')
        break
      case 500:
        if (response.data.msg == '401') {
          Message.error('登录失效,请重新登录!')
          localStorage.clear()
          setTimeout(() => {
            router.replace({
              path: '/login'
            })
          }, 1000)
        } else if (response.data.msg) {
          Message.error(lang == 'zh' ? response.data.msg : response.data.msgEng)
        } else {
          Message.error('请求出错')
        }
        break
      default:
        // if (response.data) {

        //   Message.error(response.data.msg);
        // }
        break
    }
    // Message.error(response.data.msg)
    return Promise.reject(response.data)
  },
  (error) => {
    // 接口响应失败
    return Promise.reject(error)
  }
)

export default instance
