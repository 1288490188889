import Vue from 'vue'
import App from './App.vue'

import VueI18n from 'vue-i18n'
// 引入语言包，注意路径
import zh from '../public/locales/zh'
import en from '../public/locales/en'
// 全部引入elementUI
import ElementUI from 'element-ui' // 引入elementUI
// import localeEl from 'element-plus/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/font/font.css'
// 按需引入elementUI
// import { Message,Button,Input, Dialog, Form, FormItem, Upload, Menu,Submenu,MenuItem,MenuItemGroup,Select,Option,OptionGroup,Radio,RadioGroup,RadioButton,Table,TableColumn,Pagination,DatePicker,Collapse ,CollapseItem,Popover,Image,Checkbox, Cascader,CascaderPanel }  from 'element-ui'
import router from './router' // 路由
import api from './api' // 导入api接口
import VueLazyLoad from 'vue-lazyload' // 引入懒加载
import VueCookie from 'vue-cookie' // 引入cookie
import store from './store' // 引入vuex

import './assets/icon/iconfont.css'

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  window.location = 'https://www.yocamily.com/h5/'
}

// 路由守卫
router.beforeEach((to, from, next) => {
  // debugger

  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    // 该路由需要登录权限
    if (localStorage.getItem('token')) {
      // 已登录
      next()
    } else {
      if (from.fullPath == '/login') return //如果当前页面已经是登录页面就返回
      next('/login')
    }
  } else {
    next()
  }

  // 404 页面
  //if (to.matched.length === 0) {
  // from.name ? next({
  //   name: from.name
  // }) : next('/404');
  //next('/404');
  //} else {
  next() //如果匹配到正确跳转
  //}
})

Vue.use(VueI18n)
const locale = localStorage.getItem('lang')
// 构造i18n对象
const i18n = new VueI18n({
  locale: locale || 'zh', //初始化,保证刷新页面也保留
  // 引入语言文件
  messages: {
    // 这里的属性名是任意的，您也可以把zh设置为cn等，只是后续切换语言时
    // 要标识这里的语言属性，如：this.$i18n.locale = zh|en|zh|xxx
    zh: { ...zh, ...zhLocale }, // 这里为上面通过import引入的语言包
    en: { ...en, ...enLocale }
  }
})

console.log(enLocale)
// 全部引入elementUI
Vue.use(ElementUI, {
  i18n: (key, value) => {
    console.log('i18n在el中触发!')
    console.log('key:', key)
    console.log('value:', value)
    return i18n.t(key, value)
    // console.log('res:', i18n.t(key, value))
  }
})

Vue.use(VueLazyLoad, {
  // loading: '/imgs/loading-svg/loading-bars.svg'
})
Vue.mixin({
  methods: {
    isChinese() {
      //
      let lang = this.$i18n.locale
      if (lang == 'en') {
        return false
      } else {
        return true
      }
    },
    showMessage(res) {
      if (this.isChinese()) {
        this.$message({
          message: res.msg,
          type: 'warning'
        })
      } else {
        this.$message({
          message: res.msgEng,
          type: 'warning'
        })
      }
      return
    }
  }
})
Vue.use(VueCookie)

Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.config.productionTip = false // 关闭生产模式下给出的提示

new Vue({
  render: (h) => h(App), // h函数会生成一个VNode节点，render函数得到这个VNode节点之后，返回给Vue.js的mount函数渲染成真实DOM节点，并挂载到根节点上。
  router,
  store,
  i18n
}).$mount('#app')
