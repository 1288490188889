export default {
  menu: {
    fenlei: '商品分类',
    huodong: '活动资讯',
    shouhao: '售后服务',
    fulanm: '服务栏目',
    guanyuwomen: '关于我们',
    boke: '博客页面',
    vipzhidu: '会员制度',
    shareText: '请输入搜索内容',
    megluyan: '请输入留言内容',
    recruitment: '招贤纳士',
    about: ['品牌故事', '购物须知', '客户留言', '隐私权益及网站使用条款'],
    weixiu: ['产品保养', '保修与维修', '退换货']
  },
  footer: {
    lianxi: {
      title: '联系我们',
      name: '美地营家',
      tel: '电话',
      email: '邮箱',
      time: '营业时间'
    },
    about: {
      title: '关于我们',
      ppgs: '品牌故事',
      gmqd: '购买渠道',
      ystk: '隐私条款',
      sytk: '使用条款'
    },
    aftersale: {
      title: '售后服务',
      serve: '客服留言',
      tuikuan: '退货/退款',
      ppwx: '商品维修',
      vip: '会员权益说明',
      question: '常见问题'
    },
    join: '加入我们的电子邮件列表',
    dingyue: '订阅',
    title: '加入我们的电子邮件列表，接收有关产品发布和活动、创意装备使用见解、美味食谱和户外灵感的新闻。',
    emailAdrs: '电子邮件地址',
    jiaru: '加入我们'
  },
  button: {
    chakan: '立即查看',
    gengduo: '了解更多',
    jiaruche: '加入购物车',
    qupingjia: '去评价',
    tijiao: '提交',
    lijitijao: '立即提交',
    fukuan: '立即付款',
    baoming: '点我报名',
    quxiaodingdan: '取消订单',
    quzhifu: '去支付',
    tuikuan: '申请退款',
    chakanwuliu: '查看物流',
    wuliuxiangqing: '物流详情',
    shouhuo: '确认收货',
    shenqingshouhuo: '申请售后',
    chexiao: '撤销申请',
    see: '查看申请',
    queding: '确 定',
    quxiao: '取 消',
    chakna: '去查看'
  },
  message: {
    quxiaotisi: '取消提示'
  },
  login: {
    login: '登录',
    zhuce: '注册',
    wangjips: '忘记密码',
    inputPhoneandEmier: '请输入手机号',
    inputPs: '请输入密码',
    inputCode: '请输入验证码',
    quzhuce: '去注册',
    agreementTitle: '创建账号即表示您同意',
    quanyi: '会员权益说明',
    tiaokun: '隐私权益及网站使用条款',
    and: '与',
    code: '获取验证码'
  },
  details: {
    live: '收藏',
    sku: '库存',
    yuefei: '运费',
    pinglun: '评论',
    nuber: '条评论'
  },
  submitOrder: {
    goodsXi: '商品信息',
    goodsPic: '商品金额',
    peisong: '配送费',
    heji: '合计',
    jieshuan: '去结算',
    yingfujine: '应付金额',
    wahctQce: '微信扫码',
    alipeyQce: '支付宝扫码',
    addAdress: '暂无地址，快去添加吧!'
  },
  mine: {
    menuList: ['个人资料', '收藏夹', '购物车', '订单管理', '会员积分及等级', '站内信息', '注册/登录'],
    menuList1: ['个人资料', '收藏夹', '购物车', '订单管理', '会员积分及等级', '站内信息', '退出登录'],
    menuList2: ['个人资料', '收藏夹', '购物车', '订单管理', '会员积分及等级', '站内信息', '修改密码'],
    pepol: {
      touxiang: '修改头像',
      necheng: '昵称',
      xingbie: '性别',
      plece: '请输入内容',
      phoen: '手机号',
      pleasePhoen: '清输入手机号码',
      adr: '收货地址',
      button: '填写完成',
      xuanze: '请选择'
    },
    orderList: ['待支付', '待发货', '待收货', '待评价', '已完成', '已取消', '退款/售后'],
    vipjifen: {
      myjifen: '我的积分',
      dangqiandengji: '当前等级',
      title: '获取记录',
      tiem: '时间',
      name: '项目',
      jifen: '积分',
      dengji: '等级'
    },
    messge: {
      list: ['系统通知', '到货提醒'],
      look: '去看看',
      messge: '商品已到货',
      title: '您购买的商品已到货，快去看看吧'
    },
    setting: {
      oldPs: '请输入原密码',
      oldTitle: '原密码',
      newPs: '请输入密码',
      newTitle: '新密码',
      Ps: '请再次输入新密码',
      Title: '确认密码',
      xiugmima: '修改密码'
    },
    elses: {
      cause: '请选择售后原因',
      jianshu: '请简述售后具体原因',
      miaoshu: '补充描述',
      pinjia: '填写评价',
      pinfen: '评分',
      neirong: '内容',
      aftersale: '售后商品',
      shenqing: '查看申请',
      heji: '合计',
      fahuoxiangqing: '待发货详情',
      zdshouhuotime: '自动收货时间',
      tuikuanTime: '退款提示',
      tuikuanContent: '您确定要申请退款吗 ？',
      button: '再考虑一下',
      dingdanbiaohao: '订单编号',
      xiadantime: '下单时间',
      zhifutype: '支付状态',
      pay: '已支付',
      payTime: '支付时间',
      fahuotime: '发货时间',
      shouhuotime: '收货时间',
      querentis: '收货提示',
      quxiaodingdan: '您确定要取消订单吗？',
      querenShouhuo: '您确定要确认收货吗？',
      chexiaoshenqing: '您确定要撤销退款/售后申请吗？',
      payjine: '支付金额',
      quxiaotime: '取消时间'
    }
  }
}
