/**
 * Vuex-actions
 */
 export default {
  // 保存token
  saveToken(context,token) {
    context.commit('saveToken',token);
  },
  // 保存用户信息
  saveUserInfo(context,userinfo) {
    context.commit('saveUserInfo',userinfo);
  },
   // 清空 vuex 数据
  clearData(context){
    context.commit('saveToken','');
    context.commit('saveUserInfo',{});
  }

 
}