export default {
  menu: {
    fenlei: 'SHOP',
    // huodong: 'ACTIVITIES',
    huodong: 'ACTIVITY',
    shouhao: 'AFTER SALE',
    fulanm: 'SERVICE',
    guanyuwomen: 'ABOUT US',
    boke: 'Blog'.toUpperCase(), //'COMMUNITY',
    vipzhidu: 'MEMBERSHIP SYSTEM',
    shareText: 'Search',
    megluyan: 'Leave A Message',
    recruitment: 'Join Us'.toUpperCase(),
    about: ['Our Story', 'Shopping Instructions', 'Customer Message', 'Privacy Policy & Terms Of Use'],
    weixiu: ['PRODUCT CARE', 'WARRANTY & REPAIR', 'RETURNS & REFUNDS']
  },
  footer: {
    lianxi: {
      title: 'Connect US', //'CONNECT WITH US',
      name: 'YOCAMILY',
      tel: 'Phone',
      email: 'Email',
      time: 'Hours'
    },
    about: {
      title: 'ABOUT',
      ppgs: 'Our Story',
      gmqd: 'Purchase Channel',
      ystk: 'Privacy Policy',
      sytk: 'Terms Of Use'
    },
    aftersale: {
      title: 'Service'.toUpperCase(),
      serve: 'Leave A Message',
      tuikuan: 'Returns & Refunds',
      ppwx: 'Warranty & Repair',
      vip: 'VIP Rights',
      question: 'FAQ',
      lianxi: 'Contact US'
    },
    join: 'JOIN OUR MAILING LIST',
    dingyue: 'SUBSCRIBE',
    title: 'Join our email list to receive news about product launches & events, creative gear-use insights, tasty recipes and outdoor inspiration.',
    emailAdrs: 'Email Address',
    jiaru: 'Join Us'
  },
  button: {
    chakan: 'View Now',
    gengduo: 'More',
    jiaruche: 'Add to Cart',
    qupingjia: 'Review',
    tijiao: 'Submit',
    fukuan: 'To pay',
    lijitijao: 'Submit',
    baoming: 'Join us',
    quxiaodingdan: 'Cancel',
    quzhifu: 'Pay',
    tuikuan: 'Apply for Refund',
    chakanwuliu: 'Track Delivery',
    wuliuxiangqing: 'Tracking Details',
    shouhuo: 'Confirm Receipt',
    shenqingshouhuo: 'After Sales',
    chexiao: 'Revoke',
    see: 'View Application',
    queding: 'Yes',
    quxiao: 'No',
    chakna: 'See'
  },
  message: {
    quxiaotisi: 'Confirm to Cancel'
  },
  login: {
    login: 'LOGIN',
    zhuce: 'SIGN UP',
    wangjips: 'Forget Password',
    inputPhoneandEmier: 'Please enter the email address',
    inputPs: 'Please input a password',
    inputCode: 'Please enter the verification code',
    quzhuce: 'Sign Up',
    agreementTitle: 'By creating an account, you agree',
    quanyi: 'Statement of Membership System',
    tiaokun: 'Privacy Policy & Terms of Use',
    and: 'and',
    code: 'Get Code'
  },

  details: {
    live: 'Favorite',
    sku: 'In Stock',
    yuefei: 'Freight Charge',
    pinglun: 'Review',
    nuber: 'Reviews'
  },
  submitOrder: {
    goodsXi: 'Product Information',
    goodsPic: 'Items',
    peisong: 'Shipping',
    heji: 'Subtotal',
    jieshuan: 'Check Out',
    yingfujine: 'Amount payable',
    wahctQce: 'WeChat payment',
    alipeyQce: 'Alipay payment',
    addAdress: 'Add address!'
  },
  mine: {
    menuList: ['Personal Data', 'Favorites', 'Shopping Cart', 'Order Detail', 'Membership', 'Notification', 'Sign up/Log in'],
    menuList1: ['Personal Data', 'Favorites', 'Shopping Cart', 'Order Detail', 'Membership', 'Notification', 'Log Out'],
    menuList2: ['Personal Data', 'Favorites', 'Shopping Cart', 'Order Detail', 'Membership', 'Notification', ' Change Password '],
    pepol: {
      touxiang: 'Modify Avatar',
      necheng: 'Username',
      xingbie: 'Gender',
      plece: 'Please enter content',
      phoen: 'Mobile Number',
      pleasePhoen: 'Please enter your mobile number',
      adr: 'Shipping Address',
      button: 'Complete',
      xuanze: 'Please select'
    },
    orderList: ['To Be Paid', 'To Be Shipped', 'To Be Received', 'To Be Reviewed', 'Completed', 'Canceled', 'Refund/After Sales'],
    vipjifen: {
      myjifen: 'My Points',
      dangqiandengji: 'Current Level',
      title: 'Records',
      tiem: 'Time',
      name: 'Project',
      jifen: 'Integral',
      dengji: 'Grade'
    },
    messge: {
      list: ['System Notification ', 'Arrival Reminder'],
      look: 'Go to see',
      messge: 'Goods have arrived',
      title: 'The goods you bought have arrived. Go and have a look'
    },
    setting: {
      oldPs: 'Please enter the original password',
      oldTitle: 'Original Password',
      newPs: 'Please enter the new password',
      newTitle: 'New Password',
      Ps: 'Please enter the new password again',
      Title: 'Confirm Password',
      xiugmima: 'Change Password'
    },
    elses: {
      cause: 'Please select the after-sales reason',
      jianshu: 'Please briefly explain the specific reasons for after-sales service',
      miaoshu: 'Supplementary Description',
      pinjia: 'Write A Review',
      pinfen: 'Rating',
      neirong: 'Comment',
      aftersale: 'After-sales Goods',
      shenqing: 'View Application',

      heji: 'Subtotal',
      fahuoxiangqing: 'Details to be shipped',
      zdshouhuotime: 'Automatic Receipt Time',
      tuikuanTime: 'Confirm to Refund',
      tuikuanContent: 'Are you sure you want to apply for a refund ？',
      button: 'No',
      dingdanbiaohao: 'Order Number',
      xiadantime: 'Order Time',
      zhifutype: 'State of Payment',
      pay: 'Paid',
      payTime: 'Time of Payment',
      fahuotime: 'Delivery Time',
      shouhuotime: 'Receiving time',
      querentis: 'Goods Received',
      quxiaodingdan: 'Are you sure you want to cancel the order?',
      querenShouhuo: 'Have you received the goods?',
      chexiaoshenqing: 'Are you sure you want to cancel the refund/after-sales application？',
      payjine: 'Payment Amount',
      quxiaotime: 'Cancellation time'
    }
  }
}
